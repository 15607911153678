export const annotateURLViaQueryString = (
  url: string,
  annotationName: string,
  annotationValue: string
): string => {
  try {
    const parsedURL = new URL(url);

    parsedURL.searchParams.set(annotationName, annotationValue);

    return parsedURL.toString();
  } catch {
    return url;
  }
};
