import App from 'app';
import { runRootSaga } from 'app/redux/configureStore';
import React from 'react';
import ReactDOM from 'react-dom';

import './styles/index.scss';

(async () => {
  if (
    process.env.NODE_ENV === 'development' &&
    localStorage.getItem('DISABLE_MSW') === null
  ) {
    const { mockingWorker } = await import('api/mocking');
    await mockingWorker.start({ onUnhandledRequest: 'bypass' });
  }

  if ('serviceWorker' in navigator) {
    await navigator.serviceWorker.register('/serviceworker.js', { scope: '/' });
  }

  ReactDOM.render(<App />, document.getElementById('root'));

  runRootSaga();
})();
