import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { authCache } from 'api/cache';

import { CONTENT_TYPE } from '../constants';
import { authHeaderName } from './config';
import transform from './transform';

const { REACT_APP_API_BASE: baseURL } = process.env;

export default function httpRequest<SuccessPayload>(
  config: AxiosRequestConfig
) {
  return httpClient.request<SuccessPayload>(config);
}

export const httpClient = axios.create({
  headers: {
    'Content-Type': CONTENT_TYPE.APPLICAITON_JSON,
  },
  baseURL,
  transformRequest: (data) => transformRequest(data),
  transformResponse: (data) => transformResponse(data),
  paramsSerializer,
});

httpClient.interceptors.request.use((config) => {
  if (config.headers[authHeaderName])
    config.headers[authHeaderName] = authCache.authToken;

  return config;
});

export function transformRequest(data: any, stopPaths?: string[]) {
  if (!data) {
    return data;
  }
  if (data instanceof FormData || data instanceof File) {
    return data;
  }

  return JSON.stringify(
    transform(data, {
      toSnake: true,
      stopPaths,
    })
  );
}

export function transformResponse(data: string, stopPaths?: string[]) {
  try {
    return transform(JSON.parse(data), {
      stopPaths,
    });
  } catch (e) {
    return data;
  }
}

export function paramsSerializer(params: {}) {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
  });
}

export const transformerConfigFactory = (opts: {
  requestStopPaths?: string[];
  responseStopPaths?: string[];
}): AxiosRequestConfig => ({
  transformRequest: (data) => transformRequest(data, opts.requestStopPaths),
  transformResponse: (data) => transformResponse(data, opts.responseStopPaths),
});

/** Wrap an Axios request in a handler that retries once if the request fails due to expired tokens. */
export const handleExpiredTokens = async <Data>(
  requestFactory: () => Promise<AxiosResponse<Data>>
): Promise<AxiosResponse<Data>> =>
  requestFactory().catch((error: AxiosError) => {
    // if it's not an unauthorized error, ignore it here by rethrowing it
    if (error.response?.status !== 401) throw error;

    // otherwise refresh tokens and retry
    return authCache.refreshTokens().then(
      () => requestFactory(),
      (err) => {
        authCache.clear();
        window.location.reload();
        throw err;
      }
    );
  });
