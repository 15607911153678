import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

const { REACT_APP_PDFTRON_LICENSE_KEY: license } = process.env;

type WebviewerContextType = null | {
  instance: WebViewerInstance;
  deployViewer: (newParent: HTMLElement) => void;
  returnViewer: () => void;
};

const WebviewerContext = createContext<WebviewerContextType>(null);

export const useWebviewerContext = () => useContext(WebviewerContext);

function routeWindowError(func: () => void) {
  let err;
  const listener = (event: ErrorEvent) => {
    event.preventDefault();
    err = event.error;
  };

  window.addEventListener('error', listener);
  try {
    func();
  } finally {
    window.removeEventListener('error', listener);
  }
  throw err;
}

export function useWebviewerInstance(
  viewerElementRef: React.RefObject<HTMLDivElement>
) {
  const viewerLoaded = useRef(false);
  const [instance, setInstance] = useState<WebViewerInstance>();

  useEffect(() => {
    if (viewerLoaded.current) return;
    viewerLoaded.current = true;

    WebViewer.WebComponent(
      {
        path: '/WebViewer/lib',
        fullAPI: true,
        licenseKey: license,
        preloadWorker: 'pdf',
        loadAsPDF: true,
        disabledElements: ['viewControlsDivider3', 'fullScreenButton'],
      },
      viewerElementRef.current!
    ).then(async (instance) => {
      await instance.Core.PDFNet.initialize();
      setInstance(instance);
    });
  }, [viewerElementRef]);

  useEffect(
    () => () => {
      if (instance) {
        instance.UI.dispose();
      }
    },
    [instance]
  );

  return instance;
}

export function WebviewerContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const viewerElementParentRef = useRef<HTMLDivElement>(null);
  const viewerElementRef = useRef<HTMLDivElement>(null);

  const instance = useWebviewerInstance(viewerElementRef);

  const deployViewer = useCallback((newParent: HTMLElement) => {
    const viewerElement = viewerElementRef.current;
    if (viewerElement === null) throw new Error('viewer does not exist');

    try {
      routeWindowError(() => newParent.appendChild(viewerElement));
    } catch (e) {
      if (e instanceof DOMException && e.name === 'NotSupportedError') {
        // expected error due to connectedCallback failing to reattach shadow root (it's still attached); do nothing
      } else throw e;
    }
  }, []);

  const returnViewer = useCallback(() => {
    if (viewerElementParentRef.current === null)
      throw new Error('viewer parent does not exist');
    deployViewer(viewerElementParentRef.current);

    instance?.UI.closeDocument();
  }, [deployViewer, instance]);

  return (
    <WebviewerContext.Provider
      value={
        instance
          ? {
              instance,
              deployViewer,
              returnViewer,
            }
          : null
      }
    >
      <div
        ref={viewerElementParentRef}
        style={{ height: 0, width: 0, overflow: 'hidden' }}
      >
        <div
          ref={viewerElementRef}
          data-annot="viewerElement"
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      {children}
    </WebviewerContext.Provider>
  );
}
