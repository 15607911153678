import classNames from 'classnames';
import { MouseEventHandler, useMemo, forwardRef } from 'react';

import { MaterialSymbolName } from './names';
import styles from './styles.module.scss';

interface Props {
  /** Symbol name. Editors will autocomplete this, but you can also browse these at https://fonts.google.com/icons. */
  name: MaterialSymbolName;

  /** Name to display in the hover tooltip. */
  alt: string;

  /** How large to render this. Technically affects the font-height property. Defaults to 24. */
  size?: number;

  /** Click event handler. */
  onClick?: MouseEventHandler<HTMLSpanElement>;

  /** CSS color to render with. Defaults to 'darkgrey', set to "" to let the stylesheet control this. */
  color?: string;

  /** Inject your own CSS styles here. */
  className?: string;

  /** Whether or not the symbol is filled or not. Defaults to false. */
  filled?: boolean;

  /** Stroke weight. Defaults to 400. */
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700;

  /** Modifier for stroke weight. Defaults to 0. */
  grade?: -25 | 0 | 200;

  /** Modifier for stroke weight, but based on the size you render at. Defaults to 24. */
  optical_size?: 20 | 24 | 40 | 48;
}

export default forwardRef<HTMLSpanElement, Props>(
  function MaterialIcon(props, ref) {
    const {
      name,
      alt,
      filled,
      weight,
      grade,
      size,
      optical_size,
      className,
      color,
      onClick,
    } = useMemo(
      (): Required<Props> => ({
        filled: false,
        weight: 400,
        grade: 0,
        optical_size: 20,
        color: 'darkgrey',
        size: 24,
        onClick: () => {},
        ...props,
        className: classNames(
          styles.material_symbols_outlined,
          props.className
        ),
      }),
      [props]
    );

    return (
      <span
        title={alt}
        className={className}
        style={{
          fontSize: size,
          fontVariationSettings: `'FILL' ${filled ? 1 : 0}, 'wght' ${weight}, 'GRAD' ${grade}, 'opsz' ${optical_size}`,
          color,
        }}
        onClick={onClick}
        ref={ref}
      >
        {name}
      </span>
    );
  }
);
