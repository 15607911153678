import asLazyPage from 'hocs/asLazyPage';

export const devRoutes: RoutesConfig =
  process.env.NODE_ENV === 'development'
    ? [
        {
          path: '/dev/formdemo',
          component: asLazyPage(
            () => import('../../components/molecules/reactHookForm/page')
          ),
          exact: false,
        },
      ]
    : [];
