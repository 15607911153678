import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

import { LogEntry } from 'components/organisms/logview';

import { OdataURLBuilder } from 'api/types/OdataURLBuilder';

export interface Service {
  isConfigured: boolean;

  lastSuccessDate?: Date;
  lastFailDate?: Date;
}

export interface SchedulableService {
  isRunning: boolean;

  scheduledTime?: Date;
}

export interface IndexingSourceStatus {
  name: string;
  id: string;
  documentsInProcess: number;
  totalDocuments: number;
}

export interface Status {
  indexingInstanceCreationStatus: Service;
  printRenderInstanceCreationStatus: Service;
  emailPollingStatus: Service & SchedulableService;
  ftpPollingStatus: Service & SchedulableService;
  printRenderStatus: Service;
  indexingSourceStatus: Array<IndexingSourceStatus>;
}

export abstract class AbstractSystemLogsRepo extends BaseRepo {
  abstract getLogs(
    request: OdataURLBuilder
  ): ApiResponseAsync<LogEntry[] | number>;

  abstract getStatus(releaseOrDraftProcessId: string): ApiResponseAsync<Status>;
}
