import {
  ApiResponseAsync,
  translateAxiosErrorToApiError,
} from 'container/models';

import { authHeaderName } from 'api/apiReqSagaCreator/config';
import { handleExpiredTokens } from 'api/apiReqSagaCreator/httpRequest';
import { ApiPaths } from 'api/constants';
import { ProcessInstanceDetails } from 'api/endpoints/processInstance';

import ApiUrlParams from 'constants/apiUrlParams';

import { annotateURLViaQueryString } from 'utils/painting';

import { LockDocumentResponse } from '../indexingDocuments/abstractIndexingDocumentsRepo';
import { AbstractProcessInstanceRepo } from './AbstractProcessInstanceRepo';

export class ProcessInstanceRepo extends AbstractProcessInstanceRepo {
  getProcessInstance = (link: Link): ApiResponseAsync<ProcessInstanceDetails> =>
    handleExpiredTokens(() =>
      this.client.get<ProcessInstanceDetails>(link.href, {
        headers: {
          [authHeaderName]: this.tokenAccessor(),
        },
      })
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);

  getIndexingInputFile = (
    processInstance: ProcessInstanceDetails,
    lockDocumentResponse: LockDocumentResponse
  ): ApiResponseAsync<Blob> => {
    const url = processInstance.dataModel!.variables[
      lockDocumentResponse.document.fileFieldId
    ] as string;

    if (!url)
      return Promise.resolve({
        status: 404,
        statusText: 'File does not exist for this process instance',
        data: [
          {
            code: 404,
            description: 'File does not exist for this process instance',
            message: 'File does not exist for this process instance',
          },
        ],
      });

    return handleExpiredTokens(() =>
      this.client.get<ArrayBuffer>(
        annotateURLViaQueryString(
          url,
          'request_source',
          'ProcessInstanceRepo.ts@getIndexingInputFile'
        ),
        {
          responseType: 'arraybuffer',
          headers: {
            [authHeaderName]: this.tokenAccessor(),
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf',
          },
        }
      )
    )
      .then(
        (response) =>
          new Blob([response.data], {
            type: 'application/pdf',
          })
      )
      .catch(translateAxiosErrorToApiError);
  };

  deleteProcessInstance = (
    processInstanceId: string,
    processReleaseId: string
  ) =>
    handleExpiredTokens(() =>
      this.client.delete(
        ApiPaths.processes.byId.instances.byId._({
          [ApiUrlParams.releaseOrDraftProcessId]: processReleaseId,
          [ApiUrlParams.processInstanceId]: processInstanceId,
        }),
        {
          headers: {
            [authHeaderName]: this.tokenAccessor(),
          },
        }
      )
    )
      .then((response) => response.data)
      .catch(translateAxiosErrorToApiError);
}
