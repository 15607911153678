import './wydr';

import { ConnectedRouter } from 'connected-react-router';
import { DepsProvider } from 'container/depContext';
import React, { memo, ReactElement } from 'react';
import { Provider } from 'react-redux';

// import { MockServiceFactory } from '../container/mockServiceFactory';
import ToasterContainer from 'components/molecules/toaster/container';
import { WebviewerContextProvider } from 'components/organisms/reusableWebviewer';

import { ServiceFactory } from '../container/serviceFactory';
import configureStore, { history } from './redux/configureStore';
import AppRoutes from './routes';

export const appStore = configureStore();

function AppRoot(): ReactElement {
  return (
    <Provider store={appStore}>
      <DepsProvider serviceFactory={new ServiceFactory()}>
        <WebviewerContextProvider>
          <ConnectedRouter history={history}>
            <ToasterContainer />
            <AppRoutes />
          </ConnectedRouter>
        </WebviewerContextProvider>
      </DepsProvider>
    </Provider>
  );
}

export default memo(AppRoot);
