import { ApiError, axiosResponseToApiResponse } from 'container/models';
import { useEffect, useMemo, useState } from 'react';
import { ApplicationError } from 'types/errorHandling';
import { TypedEventTarget } from 'typescript-event-target';

import { authHeaderName } from './apiReqSagaCreator/config';
import { httpClient } from './apiReqSagaCreator/httpRequest';
import { ApiPaths } from './constants';
import { LoginResult } from './endpoints/session';

const AUTH_TOKEN_LOCAL_STORAGE_ITEM = 'auth_token';
const EXCHANGE_TOKEN_LOCAL_STORAGE_ITEM = 'exchange_token';

interface tokenActivityItem {
  date: Date;
  action: string;
  tokenIsEmpty: boolean;
}

let tokenActivityLog: tokenActivityItem[] = [];
const maxActivityItems = 10;
function addToActivityLog(action: string): void {
  const newItem = {
    action,
    date: new Date(),
    tokenIsEmpty:
      (window.localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_ITEM) ?? '') !== '',
  };
  if (tokenActivityLog.length < maxActivityItems) {
    tokenActivityLog.push(newItem);
    return;
  }
  for (let i = 0; i < tokenActivityLog.length - 1; i++)
    tokenActivityLog[i] = tokenActivityLog[i + 1];
  tokenActivityLog[tokenActivityLog.length - 1] = newItem;
}

export function formatTime(date: Date): string {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
  return `${hours}:${minutes}:${seconds}:${milliseconds}`;
}

function serializeTokenActivityItem(item: tokenActivityItem): string {
  return `${formatTime(item.date)}|${item.action}|${item.tokenIsEmpty}`;
}

export function serializeActivityLog(): string {
  return (
    'activity:' +
    tokenActivityLog.map((item) => serializeTokenActivityItem(item)).join(';')
  );
}

interface AuthCacheEvents {
  authTokenUpdated: CustomEvent<string>;
}

class AuthCache extends TypedEventTarget<AuthCacheEvents> {
  get authToken(): string {
    return window.localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_ITEM) ?? '';
  }
  private set authToken(value: string) {
    window.localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_ITEM, value);

    this.dispatchTypedEvent(
      'authTokenUpdated',
      new CustomEvent('authTokenUpdated', { detail: value })
    );
  }

  get exchangeToken(): string {
    return window.localStorage.getItem(EXCHANGE_TOKEN_LOCAL_STORAGE_ITEM) ?? '';
  }
  private set exchangeToken(value: string) {
    window.localStorage.setItem(EXCHANGE_TOKEN_LOCAL_STORAGE_ITEM, value);
  }

  clear() {
    window.localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_ITEM);
    window.localStorage.removeItem(EXCHANGE_TOKEN_LOCAL_STORAGE_ITEM);

    addToActivityLog('clear');
  }

  get loggedIn(): boolean {
    return this.authToken !== '';
  }

  login(username: string, password: string) {
    return axiosResponseToApiResponse(
      httpClient.post<LoginResult>(ApiPaths.auth.login(), {
        username,
        password,
      })
    ).then(
      (result) => {
        this.authToken = result.token.accessToken;
        this.exchangeToken = result.token.refreshToken;

        addToActivityLog('login^' + username);

        return result;
      },
      (error: ApiError) => {
        throw ApplicationError.FromError(error, 'Failed to log in');
      }
    );
  }

  private refreshingToken: string = '';
  private refreshingTokenPromise: Promise<void> = Promise.resolve();
  refreshTokens() {
    // if we're already refreshing for the current exchange token, return the current promise to await on
    if (this.exchangeToken === this.refreshingToken)
      return this.refreshingTokenPromise;

    // otherwise: set the current exchange token as the one we're refreshing for...
    this.refreshingToken = this.exchangeToken;

    // and start the token refresh.
    this.refreshingTokenPromise = axiosResponseToApiResponse(
      httpClient.get<Tokens>(ApiPaths.auth.refreshToken(), {
        headers: {
          [authHeaderName]: this.authToken,
          'Refresh-Token': this.exchangeToken,
        },
      })
    ).then(
      (response) => {
        this.authToken = response.accessToken;
        this.exchangeToken = response.refreshToken;

        addToActivityLog('refresh');
      },
      (error: ApiError) => {
        throw ApplicationError.FromError(
          error,
          'Failed to refresh user session'
        );
      }
    );

    // return the refresh promise to await on.
    return this.refreshingTokenPromise;
  }

  logout() {
    if (!this.loggedIn) return Promise.resolve();

    return axiosResponseToApiResponse(
      httpClient.get(ApiPaths.users.logOut._(), {
        headers: { [authHeaderName]: this.authToken },
      })
    )
      .catch(() => {})
      .finally(() => {
        this.clear();
      });
  }
}

export const authCache = new AuthCache();

export const useAuthCache = (_authCache: AuthCache = authCache) => {
  const [currentAuthToken, _setCurrentAuthToken] = useState(
    _authCache.authToken
  );
  useEffect(() => {
    const listener = (newAuthToken: CustomEvent<string>) => {
      _setCurrentAuthToken(newAuthToken.detail);
    };

    _authCache.addEventListener('authTokenUpdated', listener);

    return () => {
      _authCache.removeEventListener('authTokenUpdated', listener);
    };
  }, [_authCache]);

  const loggedIn = useMemo(() => currentAuthToken !== '', [currentAuthToken]);

  return {
    authToken: currentAuthToken,
    loggedIn,
  };
};
